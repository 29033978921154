<template>
  <!-- eslint-disable -->
  <div class="bg-white center-container">
    <div class="content_section" v-if="type === 'subscription'">
      <p class="title_block">Thank you for Subscription</p>

      <!-- <div class="property_info">
        <p style="text-align: center;">You have subscribed to:</p>
        <p style="text-align: center;">{{ this.displayAddress }}</p>
        <p style="text-align: center;">Monthly Plan</p>
      </div> -->

      <div class="btn_row">
        <button @click="onContinue()">
          Continue
        </button>
      </div>

      <div class="timer_row">
        <p id="timer">You will be redirected in {{ this.seconsdLeft }} sec</p>
      </div>
    </div>

    <div class="content_section" v-if="type === 'purchase'">
      <p class="title_block">Thank you for Purchase</p>

      <div class="property_info">
        <p style="text-align: center;">You have purchased 30 day access to:</p>
        <p style="text-align: center;">{{ this.displayAddress }}</p>
      </div>

      <div class="btn_row">
        <button @click="onContinue()">
          Continue
        </button>
      </div>

      <div class="timer_row">
        <p id="timer">You will be redirected in {{ this.seconsdLeft }} sec</p>
      </div>
    </div>

    <div class="content_section" v-if="type === 'user'">
      <p class="title_block">Thank you for signing up</p>

      <div class="btn_row">
        <button @click="onContinue()">
          Go to Search
        </button>
      </div>

      <div class="timer_row">
        <p id="timer">You will be redirected in {{ this.seconsdLeft }} sec</p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import VueSkeletonLoader from "skeleton-loader-vue";
import { ConsoleLogger } from "@aws-amplify/core";

export default {
  name: "ThankYou",
  props: {
    searchQuery: String,
  },
  components: {
    VueSkeletonLoader,
  },
  data() {
    return {
      displayAddress: this.$route.query.address
        ? decodeURI(this.$route.query.address)
        : "",
      propertyId: this.$route.query.propertyId
        ? decodeURI(this.$route.query.propertyId)
        : "",
      type: decodeURI(this.$route.query.type),
      seconsdLeft: null,
      interval: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["isCognitoUserLoggedIn", "cognitoUser"]),
  },
  mounted() {
    var countDownTime = new Date();
    countDownTime.setSeconds(countDownTime.getSeconds() + 7);

    this.interval = setInterval(() => {
      var now = new Date().getTime();

      // Find the distance between now and the count down time
      var distance = countDownTime - now;
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // document.getElementById("timer").innerHTML =  seconds + "s";

      if (distance <= 1) {
        clearInterval(this.interval);
        this.onContinue();

        // this.$router.push({
        //   path: "forecast",
        //   query: { address: this.$route.query.address },
        // });
      } else {
        this.seconsdLeft = seconds;
      }
    }, 1000);
  },
  methods: {
    async onContinue() {
      if (this.interval) {
        clearInterval(this.interval);
      }

      if (this.type === "subscription") {
        this.$router.push({
          path: "/forecast",
          query: { address: this.$route.query.address },
        });
      } else if (this.type === "purchase") {
        this.$router.push({
          path: "/forecast",
          query: { address: this.$route.query.address },
        });
      } else if (this.type === "user") {
        if (this.displayAddress !== "") {
          this.$router.push({
            path: "/forecast",
            query: { address: this.displayAddress },
          });
        } else if (this.propertyId !== "") {
          await this.getForecast();
          window.location.href = "/forecast?address=" + this.displayAddress;
        } else {
          // Check for back to page
          console.log(this.$route.query.backTo);
          console.log(this.$route.query.search_id);

          if (
            this.$route.query.backTo === "listings" &&
            this.$route.query.search_id
          ) {
            window.location.href = "/listing/" + this.$route.query.search_id;
          } else if (this.$route.query.backTo === "listings") {
            this.$router.push({
              path: "/listings",
            });
          } else {
            // In other cases redirect to main page
            this.$router.push({
              path: "/",
            });
          }
        }
      } else {
        this.$router.push({
          path: "/",
        });
      }
    },
    async getForecast() {
      if (this.propertyId) {
        await axios
          .get("https://api.honely.com/searches/dev/v1/forecast", {
            headers: {
              Authorization:
                "Bearer " + this.cognitoUser.signInUserSession.idToken.jwtToken,
            },
            params: {
              property_id: this.propertyId,
            },
          })
          .then((response) => {
            this.displayAddress = response.data?.property_forecast?.address;
          });
      }
    },
  },
  // },
};
</script>

<style>
.bg-white {
  background-color: #f6f6f6;
}
.center-container {
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-container button {
  border: none;
  background-color: #61c857;
  color: #f6f6f6;
}

.content_section {
  width: 80%;
}

.title_block {
  display: flex;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  margin-top: 25px;
}

.property_info p {
  font-size: 14px;
  margin: 12px 0;
}

.btn_row {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.timer_row {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.timer_row p {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 12px;
}

@media only screen and (max-width: 700px) {
  .title_block {
    font-weight: 600;
    font-size: 18px;
  }

  .content_section {
    width: 90%;
  }
}
</style>
